@keyframes wiggle {
	0% {
		transform: rotate(0deg);
	}

	80% {
		transform: rotate(0deg);
	}

	85% {
		transform: rotate(5deg);
	}

	95% {
		transform: rotate(-5deg);
	}

	100% {
		transform: rotate(0deg);
	}
}

//Touch
#contact-bottom {
	background-color: #fff2f2;
	margin: auto;
	max-width: 1440px;
	padding: 0 8rem;

	.touch__content {
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		align-items: center;
	}
}

.touch {
	margin-top: 10px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding-top: 100px;
}

.touch__image {
	margin-top: auto;

	img {
		max-width: 250px;
	}
}

.touch__container {
	max-width: 600px;
	margin-right: 50px;
	margin-bottom: auto;
}

.touch__content {
	text-align: left;


	h2 {
		font-size: 2.5rem;
		margin-top: 0;
	}

	p {
		line-height: 1.5;
		margin-bottom: 25px;
	}

	input {
		border: none;
		padding: 20px;
		margin: 10px 5px 10px 5px;
	}

	textarea {
		border: none;
		padding: 20px 0;
		margin: 10px auto 30px auto;
		width: 100%;
		display: flex;
		justify-content: center;
		flex-direction: row;
		text-align: center;
	}

	.button__container {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}

	button {
		width: 100%;
	}
}

.touch__content--form-group {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr 1fr;
	width: 100%;
	padding-left: 0;
}

//Extra Content

.extra-contact {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	margin-top: 50px;
}

.extra-contact__item {
	display: flex;
	flex-direction: row;
	justify-content: center;
	text-align: left;
	align-items: center;

	img {
		animation: wiggle 2.5s infinite;

		margin-right: 25px;
	}
}

.wiggle-email {
	animation-delay: 3.75s !important;
}

.extra-conteact__item--text {
	display: flex;
	flex-direction: column;

	font-size: 1.25rem;
}

.small-text {
	font-size: 0.75rem;
}

#copyright {
	padding: 50px 0;
	margin: 0;
}
@media (max-width: 1280px){
	#contact-bottom {
		padding: 0 4rem;
	}
}

@media (max-width: 1024px){
	#contact-bottom {
		padding: 0 2rem;
	}

	
}

@media (max-width: 768px) {

	#contact-bottom {
		padding: 0 20px;
	}
	.touch {
		margin-top: 10px;
		flex-direction: column;
	}

	.touch__image {
		padding-top: 75px;
	}

	#contact-bottom .touch__content {
		width: 100%;
		padding: 0 20px;
	}

	.extra-contact {
		flex-direction: column-reverse;
		align-items: flex-start;
	}

	.touch__content input {
		padding: 20px 10px;
	}
}


@media (max-width: 425px) {
	.touch {
		padding: 0;
	}

	.touch__content--form-group {
		display: flex;
		flex-direction: column;
	}

	.touch__image {
		padding-top: 75px;
	}

	.touch__content {
		h2 {
			font-size: 2rem !important;
		}
	}

	.extra-contact {
		display: flex;
		flex-direction: column;
		margin-top: 50px;
		justify-content: center;
		align-items: center;
	}

	.extra-contact__item {
		display: flex;
		flex-direction: column;
		margin: 20px 0;
		text-align: center;

		img {
			margin: auto;
		}
	}
}

button {
	cursor: pointer;
}