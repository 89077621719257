.socialFooter {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
}

.socialFooter__block {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	color: white;
	padding: 10px;
	div {
		img {
			max-width: 25px;
			margin-right: 25px;
		}
	}
}
.bold-sf {
	font-size: 1rem;
	font-weight: bold;
}
.sf-linkedin {
	background: #0072b1;
	transition: 0.5s ease;

	&:hover {
		background: #0073b1b6;
	}
}

.sf-support {
	background-color: #2ed1c1;
	transition: 0.5s ease;

	&:hover {
		background: #2ed1c1be;
	}
}

.sf-github {
	background-color: #333;
	transition: 0.5s ease;

	&:hover {
		background: rgba(51, 51, 51, 0.733);
	}
}

.sf-github--mobile {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 50px !important;
	padding: 20px 5px;
	img {
		margin: 0 !important;
		padding: 0 !important;
		margin: 10px 0 !important;
	}

	p {
		margin-top: 0px;
	}
}

.sf-work {
	background: #ff6666;
	transition: 0.5s ease;

	&:hover {
		background: #c95151;
	}
}

.sf-about {
	background-color: #167ee5;
	transition: 0.5s ease;

	&:hover {
		background: #167de5c7;
	}
}

a {
	text-decoration: none !important;
}

.socialFooter--mobile {
	display: none;
}

.mobile__group1,
.mobile__group2 {
	display: flex;
	flex-direction: row;
}

@media (max-width: 1024px) {
	.socialFooter__block {
		div {
			height: 10vh;
			display: flex;
			justify-content: center;
			align-items: center;

			p {
				font-size: 0.8rem;
			}
		}
	}
}

@media (max-width: 768px) {
	.socialFooter {
		display: none;
	}

	.socialFooter--mobile {
		display: block;
	}
}
