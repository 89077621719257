@keyframes wiggle {
	0% {
		transform: rotate(0deg);
	}

	80% {
		transform: rotate(0deg);
	}

	85% {
		transform: rotate(5deg);
	}

	95% {
		transform: rotate(-5deg);
	}

	100% {
		transform: rotate(0deg);
	}
}

#header {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	padding: 25px 0;
}

.header__logo {
	img {
		max-width: 150px;
	}
}

.header__links--link {
	margin: 0 15px;
	text-decoration: none;
	color: black;
	font-weight: bold;
}

.follow-block {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	background-color: #01d1c1;
	padding: 0px 50px;
	color: white;
	font-weight: bold;

	img {
		width: 30px;
		margin-left: 10px;

		&:hover {
			animation: wiggle 1.5s infinite;
		}
	}
}

// animation is slow so you can see it, but probably should be .25 or .15
$thetransition: all 0.5s cubic-bezier(1, 0.25, 0, 0.75) 0s;

.hoverAnimation {
	position: relative;
	transition: all 0.15s ease-out;

	/*
  webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  -o-transition: all 0.15s ease-out;
  -ms-transition: all 0.15s ease-out;
*/

	&:before {
		content: "";
		position: absolute;
		width: 100%;
		height: 1px;
		bottom: 0;
		left: 0;
		background-color: #ff6666;
		visibility: hidden;
		-webkit-transform: scaleX(0);
		transform: scaleX(0);
		-webkit-transition: $thetransition;
		transition: $thetransition;
	}

	&:hover:before {
		visibility: visible;
		-webkit-transform: scaleX(1);
		transform: scaleX(1);
	}
}

@media (max-width: 768px) {
	#header {
		display: none;
	}
}

.header__links {
	visibility: hidden;
}