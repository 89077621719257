html {
	background-color: rgba(255, 239, 210, 0.233);
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	max-width: 1440px;
	background-color: #fff2f2;
	margin: auto;
	font-family: 'Playfair Display', serif;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

body,
html,
.body {
	overflow-x: hidden;
}

body::-webkit-scrollbar {
	width: 5px;
}

body::-webkit-scrollbar-thumb {
	background-color: #03D1C1;
	outline: 1px solid white;
}

@media (max-width: 768px) {

	body,
	html,
	.body {
		overflow-x: hidden;
	}

	body::-webkit-scrollbar {}

	body::-webkit-scrollbar-thumb {
		background-color: unset;
		outline: unset;
		border-radius: unset;
	}
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}

	50% {
		background-position: 100% 50%;
	}

	100% {
		background-position: 0% 50%;
	}
}