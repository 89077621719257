.about-banner {
	img {
		max-width: 100%;
	}
}

.about-banner--mobile {
	background: linear-gradient(-45deg, #87cefa, #5d97bb);
	background-size: 400% 400%;
	animation: gradient 5s ease infinite;

	.about-name {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	h3 {
		font-size: 1.5rem;
		color: white;
		text-align: left;
	}

	span {
		color: whitesmoke;
		font-style: italic;
		font-size: 2rem;

	}
}


#about {
	background-color: white;
}

.service-addition {
	color: #2f2f2f;
	line-height: 1.75;
	font-size: 1rem;
	text-align: start;
	padding: 0 100px;
}

#about .services {
	margin-top: 50px;
	h2 {
		text-align: center;
		margin: 20px auto;
	}
}

#about .services__content {
	padding-left: 0;
}

.services__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
	margin: 40px 0;
}

#about .services {
	display: flex;
	flex-direction: column-reverse;
	justify-content: space-evenly;
	align-items: center;
	padding: 0 50px;
	margin-top: 50px;
}

.service {
	h3 {
		text-align: left;
	}
}

#about #contact-bottom .touch {
	padding-top: 10px;
}

@media (max-width: 768px) { 

	#about .services h2 {
		padding-top: 0;
	}

	.services__grid {
		display: flex;
		flex-direction: column;
		justify-content: center	;
	}
	.service-addition {
		padding: 0 110px;
		line-height: 1.5;
	}

	#about .services__content {
		padding-left: 0;
	}
}

@media (max-width: 425px) {
	.service-addition {
		padding: 0 50px;
		line-height: 1.5;
	}

	.services {
		margin-top: 0px !important;
		padding: 0 10px !important;
		margin-bottom: -50px;

		h2 {
			font-size: 2rem !important;
			margin: auto;
			padding-bottom: 25px;
			color: #4d4d4d;
		}

		p {
			line-height: 2.5rem !important;
			color: #4d4d4d;

		}
	}


}