.slide {
	img {
max-height: 200px;
max-width: 400px;
	margin: auto;
	}

	h4 {
		color: #011c39;
		font-size: 1.5rem;
	}
}

.home-slider {
	padding: 0 8rem;
}

.slick-dots {
	transform: translateY(100%);
}

.button-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.button-git {
	background-color: #333;
	padding: 9px;

	&:hover {
		border: 2px solid #333;
		border-left: 2px solid transparent;
		border-right: 2px solid transparent;
	}
}

.button-slider {
	width: 175px;
	margin: 0 5px;
}

.button-inactive {
	background: grey;
	cursor: not-allowed;
}

#slide-arrow {
	width: 30px !important;
	transform: translate(-300%, -350%);
}

@media (max-width: 1024px) {
	.slide {
		img {
			width: 450px;
			height: 280px !important;
			margin: auto;
		}
	}

	

	.button-slider {
		padding: 15px;
		margin-right: 5px;
	}

	.button-git {
		margin-left: 5px;
		padding: 9px;
	}

	#slider {
		padding-left: 0px;
	}

	#slide-arrow {
		width: 30px !important;
		transform: translate(-30%, -400%);
	}
}

@media (max-width: 768px) {

	.slick-dots { 
		transform: translateY(20px);
	}
	#slide-arrow {
		transform: translate(100%, -200%);
	}

	#slider {
		padding: 0 10px;
	}
}

@media (max-width: 1280px){
	.home-slider {
		padding: 0 4rem;
	}
}

@media (max-width: 1024px){
	.home-slider {
		padding: 0 2rem;
	}
}

@media (max-width: 425px) {
	#slider {
		margin: 5px;
		padding: 0px;
	}

	.button-container {
		padding-bottom: 20px;
	}

	.slide {
		img {
			width: 95%;
			height: 100% !important;
		}
	}
}
